import React from 'react'
import styles from '../Work/Work.module.css'
import { Link } from 'react-router-dom'

import workContentImg1 from '../../../../assets/Images/Portfolio/portfolioHero.png'
import workContentImg2 from '../../../../assets/Images/LLApp/LLAppHero.png'
import workContentImg3 from '../../../../assets/Images/LLWebsite/LLWebsiteHero.png'
import workContentImg4 from '../../../../assets/Images/GFNav/GFNavHero.png'

const Work = () => {
  return (
    <div className={styles.entireWork}>
        <div className={styles.workContentContainer}>
            <h2 className={styles.workTitle}>
                Work
            </h2>
            <Link to='/Work-Portfolio' reloadDocument>
                <div className={styles.workContent}>
                        <img src={workContentImg1} alt='Thumbnail of the `About Portfolio Page` Link' className={styles.workContent1Image}/>
                    <h3 className={styles.workText1}>
                        <span className={styles.workText1Title}>Portfolio <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 15L6.93125 13.95L11.1312 9.75H2V8.25H11.1312L6.93125 4.05L8 3L14 9L8 15Z" fill="#279070"/>
                        </svg>
                        </span>
                    </h3>
                    <p className={styles.workText2}>
                        <span>My Portfolio Site that you are currently viewing. If you want to know more about what I learned while making it, feel free to click here!</span>
                    </p>
                    <div className={styles.workText3}>
                        <p className={styles.workContentTags}>React</p>
                        <p className={styles.workContentTags}>JavaScript</p>
                        <p className={styles.workContentTags}>CSS</p>
                        <p className={styles.workContentTags}>HTML</p>
                    </div>
                </div>
            </Link>
            <Link to='/Work-LLApp' reloadDocument>
                <div className={styles.workContent}>
                    <img src={workContentImg2} alt='Thumbnail for the `About My Mobile Application Page` Link' className={styles.workContent2Image}/>
                    <h3 className={styles.workText1}>
                        <span className={styles.workText1Title}>Mobile Application <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 15L6.93125 13.95L11.1312 9.75H2V8.25H11.1312L6.93125 4.05L8 3L14 9L8 15Z" fill="#279070"/></svg>
                        </span>
                    </h3>
                    <p className={styles.workText2}>
                        <span>My first Cross-Platform React Native App. It was completed as a Capstone Project for my certification course on Coursera through a React Native Specialization offered by Meta.</span>
                    </p>
                    <div className={styles.workText3}>
                        <p className={styles.workContentTags}>React Native</p>
                        <p className={styles.workContentTags}>JavaScript</p>
                    </div>
                </div>
            </Link>
            <Link to='/Work-LLWebsite' reloadDocument>
                <div className={styles.workContent}>
                    <img src={workContentImg3} alt='Thumbnail for the `About My First Website` Page Link' className={styles.workContent3Image}/>
                    <h3 className={styles.workText1}>
                        <span className={styles.workText1Title}>First Website <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 15L6.93125 13.95L11.1312 9.75H2V8.25H11.1312L6.93125 4.05L8 3L14 9L8 15Z" fill="#279070"/></svg>
                        </span>
                    </h3>
                    <p className={styles.workText2}>
                        <span>The first website that I created. This was done through the capstone project offered by Meta through the Coursera Front End Developer Certification.</span>
                    </p>
                    <div className={styles.workText3}>
                        <p className={styles.workContentTags}>React</p>
                        <p className={styles.workContentTags}>JavaScript</p>
                        <p className={styles.workContentTags}>CSS</p>
                        <p className={styles.workContentTags}>HTML</p>
                    </div>
                </div>
            </Link>
            <div className={styles.workContent}>
                <img src={workContentImg4} alt='Thumbnail for the `Gluten Free Navigation Application` Page link' className={styles.workContent4Image}/>
                <h3 className={styles.workText1}>
                    <span className={styles.workText1Title}>Currently in Development <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 15L6.93125 13.95L11.1312 9.75H2V8.25H11.1312L6.93125 4.05L8 3L14 9L8 15Z" fill="#279070"/></svg>
                    </span>
                </h3>
                <p className={styles.workText2}>
                    <span>I am currently working on a service that will collect and organize information to make finding allergen sensitive restaurants, grocery stores, and recipes easier for people who need them.</span>
                </p>
                <div className={styles.workText3}>
                    <p className={styles.workContentTags}>React</p>
                    <p className={styles.workContentTags}>JavaScript</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Work